import { services as Service } from "@prisma/client";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface initialStateType {
  onChosenOrderData: any;
  ServiceOrderArry: any;
  ChosenOrderId: any;
  permissionsTypes: Array<
    "Room Service" | "Minibar" | "Housekeeping" | "Maintenance" | "Spa" | "All"
  >;
  tempPermissionsTypes: Array<
    "Room Service" | "Minibar" | "Housekeeping" | "Maintenance" | "Spa" | "All"
  >;
  Table: any;
  orderCardType:
    | "rs"
    | "minibar"
    | "hk"
    | "mt"
    | "spa"
    | "ope"
    | "loading"
    | "";
  pendingOrderCount: any;
  allOrderCount: any;
  openFilterBox: boolean;
}

const initialState: initialStateType = {
  onChosenOrderData: [],
  ServiceOrderArry: [],
  ChosenOrderId: "",
  permissionsTypes: [],
  tempPermissionsTypes: [],
  Table: {},
  orderCardType: "loading",
  pendingOrderCount: null,
  allOrderCount: null,
  openFilterBox: false,
};

const onChosenOrder = createSlice({
  name: "onChosenOrder",
  initialState,
  reducers: {
    insertOnChosenorder: (state: initialStateType, action: any) => {
      state.onChosenOrderData = action.payload;
    },
    insertValidServiceOrderArry: (state: initialStateType, action: any) => {
      state.ServiceOrderArry = action.payload;
    },
    insertOnChosenId: (
      state: initialStateType,
      action: PayloadAction<{
        id: any;
        type: "rs" | "minibar" | "hk" | "mt" | "spa" | "" | "ope" | "loading";
      }>
    ) => {
      state.ChosenOrderId = action.payload.id;
      state.orderCardType = action.payload.type;
    },
    insertTable: (state: initialStateType, action: any) => {
      state.Table = action.payload;
      state;
    },
    insertPermissionsTypes: (
      state: initialStateType,
      action: PayloadAction<
        Array<
          | "Room Service"
          | "Minibar"
          | "Housekeeping"
          | "Maintenance"
          | "Spa"
          | "All"
        >
      >
    ) => {
      state.permissionsTypes = action.payload;
    },
    insertTempPermissionsTypes: (
      state: initialStateType,
      action: PayloadAction<
        Array<
          | "Room Service"
          | "Minibar"
          | "Housekeeping"
          | "Maintenance"
          | "Spa"
          | "All"
        >
      >
    ) => {
      state.tempPermissionsTypes = action.payload;
    },
    insertPendingOrderCount: (
      state: initialStateType,
      action: PayloadAction<{
        pending: any;
        all: any;
      }>
    ) => {
      state.pendingOrderCount = action.payload?.pending;
      state.allOrderCount = action.payload?.all;
    },
    switchFilterBoxStatus: (
      state: initialStateType,
      action: PayloadAction<boolean>
    ) => {
      state.openFilterBox = action?.payload;
    },
  },
});

export const {
  insertOnChosenorder,
  insertValidServiceOrderArry,
  insertOnChosenId,
  insertTable,
  insertPermissionsTypes,
  insertTempPermissionsTypes,
  insertPendingOrderCount,
  switchFilterBoxStatus,
} = onChosenOrder.actions;

export const getOnChosenOrder = (state: RootState) =>
  state.onChosenOrder.onChosenOrderData;
export const getValidServiceOrderArry = (state: RootState) =>
  state.onChosenOrder.ServiceOrderArry;
export const OnChosenId = (state: RootState) =>
  state.onChosenOrder?.ChosenOrderId;
export const getTable = (state: RootState) => state?.onChosenOrder?.Table;
export const getPermissionsTypes = (state: RootState) =>
  state.onChosenOrder?.permissionsTypes;
export const getTempPermissionsTypes = (state: RootState) =>
  state.onChosenOrder?.tempPermissionsTypes;
export const SelecteTypeValue = (state: RootState) => "";
export const getOrderCardType = (state: RootState) =>
  state.onChosenOrder?.orderCardType;
export const getPendingOrderCount = (state: RootState) =>
  state.onChosenOrder?.pendingOrderCount;
export const getAllOrderCount = (state: RootState) =>
  state.onChosenOrder?.allOrderCount;
export const getOpenFilterBox = (state: RootState) =>
  state.onChosenOrder?.openFilterBox;

export default onChosenOrder.reducer;
